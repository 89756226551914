@import "src/styles/variables";
.subaccounts {
  padding: 27px;
  & > h3 {
    font-size: map-get($font-size, large);
    font-weight: 700;
  }
  &__heading {
    text-align: right;
    @include flex(flex-end, space-between);
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
      width: 100%;
      align-items: start;
    }
  }
  &__content {
    table {
      th {
        &:nth-child(2) {
          width: 25%;
        }
      }
    }
    @media (max-width: 768px) {
      table {
        th {
          &:nth-child(2) {
            width: 50%;
          }
        }
      }
    }
    span {
      cursor: pointer;
      svg {
        transition: 0.5s all;
        path {
          fill: var(--color-card-text);
        }
      }
      &.rotate {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__wallet {
    gap: 60px;
    @include flex(center);
    &__text {
      width: 40%;
      @media (max-width: 768px) {
        width: 70%;
        flex: 1 1;
      }
    }
    &__img {
      img {
        cursor: pointer;
      }
    }
  }
}