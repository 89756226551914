@import "src/styles/variables";
.headerClass {
  background-color: transparent;
  position: absolute;
}
.firstSection {
  padding-top: 170px;
  padding-bottom: 290px;
  position: relative;
  background-size: cover;
  background-position: center;
  * {
    white-space: pre-wrap;
  }
  &__bgAnimation {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #001c33;
    overflow: hidden;
    z-index: -1;
    &::after {
      content: '';
      background: url(../../images/main/cubes.png);
      width: 800%;
      position: absolute;
      top: -400%;
      right: -400%;
      height: 800%;
      opacity: 0.3;
      animation-name: rotate;
      animation-duration: 140s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  &__svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    stroke: none;
    pointer-events: none;
    height: 120px;
    fill: #fff;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    z-index: 1;
  }
  &__content {
    h1 {
      font-size: 50px;
      font-weight: 700;
      color: white;
    }
  }
}
.secondSection {
  margin-top: -200px;
  &__card {
    z-index: 1;
    position: relative;
    &__overflow {
      overflow: auto;
    }
  }
}
.thirdSection {
  padding-top: 120px;
  padding-bottom: 90px;
  border-bottom: 1px solid rgba(128, 137, 150, .1);
  &__container {
    @include flex(center, unset);
  }
  &__left {
    width: 35%;
    @include flex(center, unset);
    flex-direction: column;
    gap: 2rem;
    &__content {
      @include flex(flex-start,  unset);
      width: 100%;
      gap: 20px;
      & > div {
        &:first-child {
          width: 70px;
          height: 70px;
          @include flex(center, center);
          font-size: 30px;
          padding: 10px;
          transition: all .3s;
          -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
          -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
          box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
          border-radius: 100%;
        }
        &:last-child {
          flex: 1;
        }
      }
      h2 {
        font-size: 18px;
        color: black;
      }
    }
  }
  &__right {
    width: 70%;
    img {
      width: 100%;
    }
  }
}
.fourthSection {
  padding-top: 120px;
  padding-bottom: 90px;
  &__heading {
    text-align: center;
    h1 {
      font-size: 45px;
      color: black;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
    }
  }
  &__content {
    margin-top: 48px;
    @include flex(stretch, unset);
    & > div {
      padding: 0 10px;
      & > div {
        padding: 30px;
        transition: 0.3s all;
        position: relative;
        height: 100%;
        bottom: 0;
        & > div {
          &:first-child {
            font-size: 30px;
            width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            display: inline-block;
            position: relative;
            z-index: 1;
            background-color: #fff;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
            -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
            box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
            margin-bottom: 20px;
            border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
            color: #3a83f1;
          }
        }
        &:hover {
          bottom: 5px;
        }
        h3 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p{
          color: #677286;
        }
      }
    }
    h3 , p {
      white-space: pre-line;
    }
  }
}
.fifthSection {
  position: relative;
  &__content {
    @include flex(center, space-between);
    width: 100%;
    & > div {
      text-align: center;
      padding: 3rem 0;
      color: white;
      h2{
        font-size: 40px;
        font-weight: 700;
        color: white;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
.sixthSection {
  background-color: #f8f9fa ;
  text-align: center;

  h2 {
    font-size: 45px;
    color: black;
    font-weight: 700;
  }
  p {
    font-size: 18px;
  }
}
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 1220px) {
  .thirdSection {
    &__container {
      flex-wrap: wrap;
    }
    &__left, &__right {
      width: 100%;
    }
  }
  .fourthSection {
    &__content {
      flex-wrap: wrap;
      &> div {
        margin-bottom: 20px;
      }
    }
  }
  .fifthSection {
    &__content {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 767px) {
  .firstSection, .secondSection, .thirdSection, .fourthSection {
    h1 {
      font-size: 40px;
      font-weight: 700;
    }
  }
  .fifthSection {
    &__content {
      & > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .firstSection, .secondSection, .thirdSection, .fourthSection {
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
  }
}