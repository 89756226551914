@import "src/styles/variables";
.footer {
  padding-top: 80px;
  padding-bottom: 16px;
  &__top {
    @include flex(stretch, space-between);
    & > div {
      gap: 10px;
      height: 100%;
      h5 {
        color: black;
        font-weight: 600;
      }
      .titleShape {
        span {
          display: block;
          width: 45px;
          height: 1px;
          background-color: #3a83f1;
        }
      }
      ul {
        padding: 0;
        li {
          list-style-type: none;
          padding: 0;
          a {
            text-decoration: none;
            color: unset;
          }
        }
      }
    }
    &__socialIcons {
      a {
        margin-right: 10px;
        &:hover {
          color: #3a83f1
        }
      }
    }
  }
  &__bottom{
    &__btn {
      font-size: 15px;
      padding: 8px 16px;
      font-weight: 500;
      &__large {
        width: 190px;
      }
    }
    &__padding {
      padding: unset;
    }
    &__dropDown {
      position: absolute;
      z-index: 1000;
      display: none;
      float: left;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 0.25rem;
      padding: 15px;
      border: 0;
      -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      margin-top: 6px;

      overflow: hidden;
      will-change: transform;
      top: 0px;
      left: 0px;
      transform: translate3d(0px, -140px, 0px);

      &.show {
        display: block;
      }

      &.currency {
        width: 90px;
      }
    }
    &.lang {
      width: 200px !important;
    }
  }
}