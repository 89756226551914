.container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 250px 1fr;
  height: 100vh;
}
.wrapper {
  background-color: var(--background);

}
.header {
  grid-column: 1 / -1;
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.sidebar {
  @media (max-width: 768px) {
    display: none;
  }
}

.main {
  max-height: calc(100vh - 70px);
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
}

.scrollable-item {
  margin-bottom: 15px;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}