@import "src/styles/variables";
.mining {
  &__heading {
    font-size: map-get($font-size, normal);
  }
  &__subtitle {
    font-size: map-get($font-size, small);
    color: map-get($colors, black2);
  }
  &__urls {
    margin-top: 20px;
    padding-right: 50px;
    & > div {
      @include flex(center, space-between);
      font-size: map-get($font-size, regular);
      margin-bottom: 10px;
      & > div {
        &:first-child {
          width: 10%;
        }
        &:last-child {
          color: var(--color-card-text);
          width: 90%;
          background-color: var(--background);
          border-radius: 15px;
          padding: 12px 20px;
          @include flex(center, space-between);
          svg {
            cursor: pointer;
            &:hover {
              path {
                fill: map-get($colors, green);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mining {
    &__heading {
      font-size: map-get($font-size, regular);
    }
    &__urls {
      padding-right: 0px;
      & > div {
        @include flex(flex-start, space-between);
        font-size: map-get($font-size, small);
        flex-direction: column;
        margin-bottom: 10px;
        & > div {
          &:first-child {
            width: 100%;
            margin-bottom: 10px;
            color: map-get($colors, black2);
          }
          &:last-child {
            width: 100%;
            flex-wrap: wrap;
            padding: 10px;
          }
        }
      }
    }
  }
}