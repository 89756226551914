.table {
  th {
    font-size: 15px;
    order-top: 0;
    border-bottom: 0;
    font-weight: 500;
    opacity: .8;
    padding: 10px 20px;
    vertical-align: middle;;
  }
  td {
    font-size: 14px;
  }
  &__customTd {
    padding: 0 20px;
    align-items: center;
    & > div {
      margin-right: 0.5rem;
      img {
        width: 33px;
      }
    }
    & > p{
      margin: 0;
      line-height: 18px;
      span {
        color: #677286;
        display: block;
      }
    }
  }
}
.wrapper {
  overflow: auto;
}
.customTable {
  border-radius: 10px;
  width: 100%;
  thead {
    width: 100%;
  }
  tr {
    &.odd {
      background-color: rgba(0, 0, 0, .05);
    }
    &.even {
      background-color: transparent;
    }
  }
  th {
    margin-bottom: 0;
    background: #3a83f1;
    color: #ffffff;
    font-weight: 600;
  }
  th,td {
    padding: 10px;
  }
}
