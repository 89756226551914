@import "src/styles/variables";
.navigation {
  display: none;
  background-color: var(--color-card);
  position: fixed;
  bottom: 0;
  height: auto;
  left: 0;
  width: 100%;
  z-index: 101;
  &__item {
    padding: 10px 15.5px;
    color: map-get($colors, black2);
    @include flex(center, center);
    flex-direction: column;
    font-size: map-get($font-size, small);
    span {
      text-align: center;
    }
    & > svg {
      path {
        //stroke: #AEAEB7;
      }
    }
    &.active {
      color: map-get($colors, green2);
      svg {
        path {
          stroke: map-get($colors, green2);
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .navigation {
    @include flex(center, space-between);
  }
}