@import "src/styles/variables";
.settings {
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  h5 {
    font-size: 17px;
    color: #050f19;
    font-weight: bold;
    padding: 10px;
  }
  &__text, ul {
    font-size: 14px;
    color: #050f19;
    padding: 10px;
    width: 100%;
    display: block;
    li {
      list-style-type: disc;
    }
  }
  table {
    font-size: 13px;
    thead th {
      background-color: white;
      font-weight: 600;
    }
    tbody td {
      i {
        display: inline-block;
        margin-right: 10px;
        color: red;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  &__qr {
    margin-bottom: 0;
    h6 {
      padding: 0 10px;
      margin-bottom: 0;
    }
    p{
      font-weight: 500;
      font-size: 12px;
      padding-top: 10px;
    }
    &__content {
      @include flex(center, unset);
    }
    &__alert {
    }
  }
}
@media (max-width: 999px) {
  .settings {
    width: 100%;
    overflow: auto;
    &__qr {
      &__content {
        display: block;
      }

      &__alert {
        display: block;
      }
    }
  }
}

@import 'src/styles/variables';
.mainSettings {
  &__wrapper {
    display: block;
  }
  h1 {
    font-size: map-get($font-size, big2);
    font-weight: 700;
    padding: 10px;
  }
  &__2fa {
    width: 60%;
    margin-top: 20px;
    display: inline-block;
    &__heading {
      margin-bottom: 10px;
      h2 {
        font-size: map-get($font-size, big);
        font-weight: 600;
      }
      h4 {
        font-size: map-get($font-size, small);
        font-weight: 600;
      }
    }
    &__qr {
      @include flex(center);
      gap: 20px;
      & > div {
        &.img {
          background-color: white;
        }
        & > p {
          margin-bottom: 10px;
          font-size: map-get($font-size, tiny);
        }
      }
    }
    &__btn {
      margin-top: 10px;
      @include flex(center, space-between);
      input {
        &.custom {
          font-weight: 600;
          border: none;
        }
      }
    }
  }
  &__tel {
    h2 {
      font-size: map-get($font-size, big);
      font-weight: 600;
      margin-bottom: 10px;
    }
    &__main {
      font-weight: bold;
    }
  }
  &__reward {
    h2 {
      font-size: map-get($font-size, big);
    }
    padding: 10px;
    margin-top: 20px;
    width: 60%;
    &__btn {
      @include flex(center, space-between);
      margin-top: 15px;
      & > span {
        color: #f7931a;
        font-size: map-get($font-size, small);
      }
      button {
        &.small {
          margin-top: 20px;
          padding: 5px 25px;
          border-radius: 10px;
          font-size: map-get($font-size, small);
        }
      }
    }
  }
  &__apiKeys {
    padding: 10px;
    margin-top: 20px;
    width: 40%;
    margin-bottom: 20px;
    &__financial {
      width: 70%;
    }
    h2 {
      font-size: map-get($font-size, big);
    }
    &__btn {
      text-align: right;
      button {
        &.small {
          margin-top: 20px;
          padding: 5px 25px;
          border-radius: 10px;
          font-size: map-get($font-size, small);
        }
      }
    }
  }
  &__langs {
    display: none;
  }
  &__infoWrapper {
    @include flex(flex-start, space-between);
    &__info {
      margin-top: 20px;
      h2 {
        font-size: map-get($font-size, big);
        font-weight: 600;
        margin-bottom: 20px;
      }
      ul {
        li {
          margin-bottom: 10px;
          list-style-type: none;
          span {
            color: #7E8486;
            font-size: map-get($font-size, small);
          }
        }
      }
    }
  }
  &__mobilePage {
    display: none;
  }
}

@media (max-width: 768px) {
  .mainSettings {
    &__mobilePage {
      display: block;
    }
    &__infoWrapper {
      flex-direction: column;
    }
    &__wrapper {
      display: none;
    }
    & > h1 {
      font-size: map-get($font-size, medium);
      padding: 10px;
      margin-top: 15px;
    }
    &__2fa {
      width: 100%;
      &__heading {
        h2 {
          font-size: map-get($font-size, medium);
          font-weight: 600;
        }
      }
      &__qr {
        flex-direction: column;
      }
      &__btn {
        gap: 10px;
      }
    }
    &__reward {
      h2 {
        font-size: map-get($font-size, medium);
      }
      width: 100%;
      &__btn {
        @include flex(center, space-between);
        margin-top: 15px;
        & > span {
          color: #f7931a;
          font-size: map-get($font-size, small);
        }
        button {
          &.small {
            margin-top: 20px;
            padding: 5px 15px;
            border-radius: 10px;
            font-size: map-get($font-size, small);
          }
        }
      }
    }
    &__apiKeys {
      width: 100%;
      margin-bottom: 20px;
      h2 {
        font-size: map-get($font-size, medium);
      }
      &__btn {
        button {
          &.small {
            margin-top: 20px;
            padding: 5px 15px;
            border-radius: 10px;
            font-size: map-get($font-size, small);
          }
        }
      }
    }
    &__langs {
      display: block;
      ul {
        li {
          border-radius: 5px;
          background-color: white;
          padding: 10px;
          margin-bottom: 10px;
          a {
            @include flex(center, unset);
            gap: 20px;
            color: map-get($colors, black);
            text-decoration: none;
          }
          &.active {
            background-color: map-get($colors, green);
            a {
              color: white;
            }
          }
        }
      }
    }
  }
}
