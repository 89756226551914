.authCard {
  padding: 0;
  &__title {
    h3 {
      font-size: 1.75rem;
      font-weight: 700;
    }
    p {
      font-size: 15px;
      color: #677286;
    }
    & > div {
      margin-top: 0px;
      display: inline-block;
      font-size: 14px;
      padding: 0.6rem 1.3rem;
      color: #677286;
      border: 1px solid rgba(128, 137, 150, .1);
      border-radius: 50rem;
      i {
        display: inline-block;
        margin-right: 5px;
      }
      span {
        color: #28a745 !important;
      }
    }
  }
  &__body {
    padding: 30px;
    span {
      &.error {
        color: red;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__topBtn {
      text-decoration: none;
      font-size: 15px;
      a {
        text-decoration: none;
        font-weight: 400;
      }
    }
    &__bottomBtn {
      font-size: 14px;
      text-align: center;
      padding-top: 0.5rem;
      font-weight: 400;
      color: #677286;
      a {
        text-decoration: none;
        color: #3a83f1;
      }
    }
    &__timer {
      font-size: 14px;
      font-weight: 400;
      color: #ccc;
    }
  }
}