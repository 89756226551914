@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #1e2730;
    --bs-primary-rgb: 30, 39, 48;
  }
}
.dropdown-menu {
  --bs-dropdown-min-width: 0;
}
:root {
  --bs-border-width: 0;
}
body {
  font-family: 'Montserrat', serif;
  --color-background: white;
  --color-card: white;
  --color-text: black;
  --color-icon: #aeaeb7;
  --color-card-text: black;
  --color-active-text: #e2f1fd;
  --background: #f4f4f9;
  --background-item: #d5e3f7;
  --background-btn: #1e2730;
  --calendar-background: #EBF8F0;
  --color-btn: white;
  --color-list-item: #1e2730;
}
body.dark {
  --color-background: #0D0D0D;
  --color-card: #0D0D0D;
  --color-card-text: white;
  --color-active-text: #353636;
  background-color: black;
  --calendar-background: #353636;
  --background: #151515;
  --color-icon: white;
  --color-btn: #1e2730;
  --color-text: #D8DDD9;
  --background-item: #b5cff5;
  --background-btn: #b5cff5;
  --color-list-item: #1e2730;
}
 .langs-wrap {
  position: relative;
}
 .langs-drop {
  position: absolute;
  border-radius: 10px;
  top: 100%;
  width: 170px;
  margin-right: -20px;
  margin-top: 10px;
  right: 0;
  z-index: 10;
  padding: 1rem 1.5rem 1rem 1rem;
  background: var(--color-card);
  -webkit-box-shadow: 0 0 40px rgba(158, 192, 225, 0.3);
  box-shadow: 0 0 40px rgba(158, 192, 225, 0.3);
}
 .langs-drop:before {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  bottom: 100%;
  right: 45px;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent white transparent;
  -webkit-transform: none !important;
  transform: none !important;
}
@media (max-width: 767px) {
   .langs-drop {
    margin-top: 10px;
    //left: 0;
    margin-right: -33px;
  }
}
 .langs-drop li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 0 5px 0 10px;
}
 .langs-drop li:last-child {
  border: none;
}
 .langs-drop li a {
  font-size: 16px;
  color: var(--color-card-text);
  font-weight: 500;
  padding: 0.5rem 0;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
 .langs-drop li a:hover {
  color: #40c067;
}
 .langs-drop li.active {
  background: var(--color-active-text);
}
.fi {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 25px;
  line-height: 1em;
}
.fi:before {
  content: ' ';
}
.fi-ru {
  background-image: url(../images/main/flags/ru.svg);
}

.fi-es {
  background-image: url(../images/main/flags/es.png);
}

.fi-ar {
  background-image: url(../images/main/flags/ar.png);
}

.fi-kz {
  background-image: url(../images/main/flags/kz.svg);
}

.fi-pt {
  background-image: url(../images/main/flags/pt.png);
  margin-right: 0 !important;
}

.fi-tr {
  background-image: url(../images/main/flags/tr.svg);
}

.fi-us {
  background-image: url(../images/main/flags/us.svg);
}
body {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 26px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #677286;
}
h1 {
  font-weight: 700;
  color: #050f19;
}
h2 {
  font-weight: 600;
  color: #050f19;
}
h3 {
  color: #050f19;
  font-weight: 700;
}
a, li {
  font-weight: 500;
}
.btn {
  font-weight: 500;
  font-size: 16px;
  padding: 12px 24px;
}
.text-danger {
  color: #dc3545;
}
.btn.focus,
.btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.btn-sm {
  font-size: 15px;
  padding: 6px 12px
}

.btn-primary {
  background-color: #3a83f1;
  border-color: #3a83f1
}

.btn-outline-primary {
  border-color: #3a83f1;
  color: #3a83f1
}

.btn-outline-primary:hover, .btn-primary:hover {
  background-color: #283a5e;
  border-color: #283a5e;
}

.btn-link {
  color: #677286;
  font-weight: 600
}

.btn-link:hover {
  color: #3a83f1
}
.container {
  max-width: 1200px
}

@media (min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1200px
  }
}

.card {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  border: 0;
  margin-bottom: 30px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  overflow: hidden;
  transition: all .3s;
}
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}
.border-top-gray {
  border-top-color: rgba(128, 137, 150, .5) !important;
}
.icon-element {
  font-size: 30px;
  color: #050f19;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  position: relative;
  z-index: 1;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
  &-sm {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto
}

.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none
}

.dropdown-toggle .filter-option .filter-option-inner {
  padding-right: inherit
}

.dropdown-toggle .filter-option-inner-inner {
  overflow: hidden
}

.dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden
}

.dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle
}

.dropdown-toggle {
  background-color: #eee;
  border-color: #eee;
  color: black;
}

.input-group .form-control .dropdown-toggle {
  border-radius: inherit
}

.dropdown-toggle {
  width: 100%
}

.dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

 .dropdown-menu>.inner:focus {
  outline: 0 !important
}

.dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dropdown-menu li {
  position: relative
}

 .dropdown-menu li.active small {
  color: rgba(255, 255, 255, .5) !important
}

.dropdown-menu li.disabled a {
  cursor: not-allowed
}

.dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em
}

.dropdown-menu li a span.check-mark {
  display: none
}

.dropdown-menu li a span.text {
  display: inline-block
}

.dropdown-menu li small {
  padding-left: .5em
}
.select-picker .dropdown-toggle .filter-option-inner-inner {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.generic-table .table {
  color: #050f19;
  margin-bottom: 0
}

.generic-table .table thead tr {
  background-color: rgba(128, 137, 150, .05)
}

.generic-table .table thead th {
  border-top: 0;
  border-bottom: 0;
  font-weight: 500;
  font-size: 15px;
  opacity: .8
}

.generic-table .table td,
.generic-table .table th {
  vertical-align: middle;
  border-top-color: rgba(128, 137, 150, .1);
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500
}

.generic-table .table tbody tr:hover {
  background-color: rgba(128, 137, 150, .05)
}

.generic-table .numeral.red {
  color: #ff2e2e
}

.generic-table .numeral.green {
  color: #39ba5e
}

.generic--table .table tbody tr:hover {
  background-color: transparent
}

.generic-table-negative {
  margin-top: -200px;
  position: relative;
  z-index: 1
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529
}

.table td,
.table th {
  padding: 10px 20px;
  vertical-align: top;
}

.table thead tr, .table thead th {
  font-weight: 400;
  background-color: rgba(128, 137, 150, .05);
}

.table tr, .table th {
  vertical-align: bottom;
  border-top: 1px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

.table-sm td,
.table-sm th {
  padding: .3rem
}
