@import "variables";
@mixin btcCards() {
  .card {
    &__label {
      color: map-get($colors, black2);
      font-size: map-get($colors, small);
    }
    &__title {
      color: var(--color-card-text);
      font-size: map-get($font-size, large);
      &.green {
        color: map-get($colors, green);
      }
      &.red {
        color: map-get($colors, red);
      }
    }
    &__subtitle {
      color: map-get($colors, black2);
      font-size: map-get($font-size, medium);
      margin-left: 10px;
    }
    &__equal {
      color: map-get($colors, black);
      font-size: map-get($font-size, medium);
    }
    &__img {
      display: inline-block;
      margin-right: 20px;
    }
    &.withIcon {
      @include flex(center, space-between);
    }
  }
  .container {
    @include flex(stretch);
    gap: 16px;
    margin-bottom: 16px;
    & > div {
      flex: 1;
      &:first-child {
        flex: 2;
      }
    }
  }

  @media (max-width: 768px) {
    .card {
      &__label {
        font-size: map-get($colors, small);
      }
      &__title {
        font-size: map-get($font-size, big);
      }
      &__subtitle {
        font-size: map-get($font-size, normal);
      }
      &__equal {
        font-size: map-get($font-size, normal);
      }
      &__img {
        margin-right: 10px;
        width: 40px;
      }
    }
  }
}
@mixin btcChart() {
  .chart {
    margin-bottom: 16px;
    &__heading {
      @include flex(center);
      gap: 20px;
      & > span {
        font-size: map-get($font-size, normal);
      }
      &__chips {
        @include flex(center);
        gap: 12px;
        button {
          color: map-get($colors, gray2);
          border: 1px solid map-get($colors, gray2);
          border-radius: 16px;
          padding: 2px 12px;
          font-size: map-get($font-size, tiny);
          white-space: nowrap;
          &.active{
            color: map-get($colors, green);
            border: 1px solid map-get($colors, green);
            background-color: var(--background-item);
          }
        }
      }
    }
  }
}