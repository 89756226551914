
.input {
  height: 50px;
  line-height: inherit;
  font-size: 15px;
  color: #050f19;
  border: 0;
  background-color: #f8f9fa;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: .25rem;
  padding-left: 20px;
  padding-right: 20px;
  &:read-only{
    background-color: #e9ecef;
    opacity: 1;
  }
  &:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #eee
  }

  &::placeholder {
    color: rgba(128, 137, 150, .8)
  }

  &:-ms-input-placeholder {
    color: rgba(128, 137, 150, .8)
  }

  &::-ms-input-placeholder {
    color: rgba(128, 137, 150, .8)
  }
  &__checkbox {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.formGroup {
  margin-bottom: 1rem;
}
.customControl {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.label {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  a {
    font-weight: 500;
    text-decoration: none;
  }
}

.error {
  color: red;
  font-size: 14px;
  font-weight: 400;

}