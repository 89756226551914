@mixin flex($center: unset, $justify: unset) {
  display: flex;
  align-items: $center;
  justify-content: $justify;
}


@mixin btn() {
  padding: 10px 20px;
  font-style: normal;
  font-weight: 600;
  border-radius: 13px;
  text-align: center;
  &.active{
    background-color: #40C067;
    color: white;
  }

}

$colors: (
    green: #1e2730,
        green2: #3a83f1,
    aqua: #DDF6EB,
    gray: #EDECEC,
    light-gray: #f4f4f9,
    light-gray2: #FAFAFC,
    light-green: #DDF6EB,
    gray2: #D5D5D5,
    gray3: #7A7B7B,
    red: #F63131,
    black2: #757878,
    orange: #F7931A,
    black: #202224,
    black3: #353636
);
$font-size: (
        tiny: 12px,
        small: 14px,
        regular: 16px,
        normal: 18px,
        medium: 20px,
        big: 24px,
        big2: 32px,
        large: 36px,
)