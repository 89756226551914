@import "src/styles/variables";
.cabinetLayout {
  &__contentNavigation {
    display: none;

    @media (max-width: 768px) {
      @include flex(center, unset);
    }
  }
  &__navigation, &__contentNavigation {
    padding: 10px;
    gap: 10px;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1rem 0;
    button {
      background-color: #fff;
      color: #677286;
      -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: .5rem 1rem;
      &.active{
        background-color: #3a83f1;
        border-color: #3a83f1;
        color: white;
      }
    }
  }
  &__heading {
    h3 {
      font-weight: 700;
      font-size: 1.75rem;
      margin-bottom: 10px;
      color: black;
    }
  }
  &__content {
  }
  &__mining {
    background-color: #f8f9fa;
    padding-top: 120px;
    &__heading {
      margin-bottom: 1rem;
      @include flex(center, unset);
      gap: 10px;
      h2 {
        font-weight: 300;
        color: #050f19;
        font-size: 40px;
      }
      button {
        background-color: transparent;
      }
    }
    &__urls {
      padding-bottom: 120px;
      width: 100%;
      &__item {
        width: 100%;
        @include flex(center, unset);
        margin-bottom: 20px;
        gap: 20px;
        font-weight: 400;
        font-size: 14px;
        & > div {
          &:first-child {
            width: 20%;
          }
        }
        &__input {
          width: 70%;
          @include flex(center, unset);
          input {
            padding: 12px 24px;
            border-radius: 0;
            width: 100%;
          }
        }
        button {
          border-radius: 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .cabinetLayout {
    &__navigation {
      gap: 10px;
    }
    &__mining {

      &__heading {
        flex-wrap: wrap;
      }
      &__urls {
        padding-bottom: 300px;
      }
    }
  }
}