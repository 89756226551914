@import "src/styles/variables";
@import "src/styles/cabinet.variables";
.workers {
  @include btcChart();
  &__search {
    input {
      background-color: var(--background);
    }
  }
  &__cards {
    @include btcCards();
    &__first {
      @include flex(stretch);
      gap: 16px;
      margin-bottom: 16px;
      & > div {
        flex: 1;
      }
    }
  }
  &__content {
    &__heading {
      @include flex(center, space-between);
      & > div {
        &:first-child {
          width: 30%;
        }
      }
    }
  }
  span {
    svg {
      transition: 0.5s all;
      path {
        fill: var(--color-card-text);
      }
    }
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
@media (max-width: 768px) {
  .workers {
    &__cards {
      &__first {
        flex-wrap: wrap;
        & > div {
          width: 100%;
          flex: unset;
        }
      }
    }
    &__content {
      &__heading {
        flex-direction: column;
        & > div {
          &:first-child {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}