.observer {
  background-color: var(--background);
  padding-bottom: 100px;
  span {
    svg {
      transition: 0.5s all;
      path {
        fill: var(--color-card-text);
      }
    }
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}