.observers {
  &__arrowIcon {
    background-color: #3a83f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    padding: 5px;
  }
}