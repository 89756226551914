@import "src/styles/variables";


.header {
  &__cabinet {
    @include flex(center, flex-end);
    padding: 0 30px;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  &__fixed {
    background-color: white !important;
    position: fixed !important;
    z-index: 10;
    box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    animation: animFromTop 0.5s;
    a {
      color: #050f19 !important;
    }
  }
  &__wrapper {
    position: relative;
    min-height: 75px;
    z-index: 2;
    @include flex(center, unset);
    top: 0;
    background-color: #1e2730;
    width: 100%;
    margin: 0 auto;
  }
  &__inner {
    padding: 1rem 0;
    width: 100%;
  }
  @include flex(center, space-between);
  background-color: transparent;
  &__mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &__left {
    img {
      cursor: pointer;
    }
  }
  &__right {
    color: white;
    @include flex(center, unset);
    gap: 15px;
    & > * {
      width: fit-content;
      &:last-child{
        width: auto;
      }
    }
    a {
      color: white;
    }
    &__lang {
      margin-left: auto;
      button {
        @include flex(center, center);
        gap: 10px;
      }
      span {
        color: #1e2730;
      }
    }
    &__logout {
      margin-left: 20px;
    }
  }
}
@media (max-width: 660px) {
  .header {
    &__right {
      gap: 0;
      .none {
        display: none;
      }
      select {
        max-width: 150px;
      }
    }
    &__left {
      img {
        width: 100px;
      }
    }
  }
}
@keyframes animFromTop {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}