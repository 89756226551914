@import "src/styles/variables";
@import "src/styles/cabinet.variables";
.home {
  &__blocks {
    @include btcCards();
    &__first {
      @include flex(stretch);
      gap: 16px;
      margin-bottom: 16px;
      & > div {
        flex: 1;
        &:first-child {
          flex: 2;
        }
      }
    }
  }
  &__desktop {
    display: block;
  }
  &__mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .home {
    &__blocks {
      &__first {
        flex-wrap: wrap;
      }
    }
    &__desktop {
      display: none;
    }
    &__mobile {
      display: block;
      &__extend {
        @include flex(center);
        flex-direction: column;
        gap: 10px;
        & > div {
          width: 100%;
        }
      }
      &__equalize {
        & > div {
          &:first-child {
            flex: 1;
          }
        }
      }
    }
  }
}