.alert {
  padding: 10px;
  width: 300px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.098039) 5px 4px 10px 0;
  position: absolute;
  right: 50px;
  top: 100px;
  z-index: 50;
  border-radius: 5px;
  font-size: 15px;
  animation: anim 0.3s;
  &.success {
    background-color: #28a745;
  }
  &.error {
    background-color: red;
  }
  i {
    position: absolute;
    cursor: pointer;
    font-size: 14px;
    top: 2px;
    right: 2px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
}

@keyframes anim {
  from {
    transform: scale(0);
  } to {
  transform: scale(1);
  }
}