@import "src/styles/variables";
@import "src/styles/cabinet.variables";
.home {
  &__blocks {
    @include btcCards();
    &__first {
      @include flex(stretch);
      gap: 16px;
      margin-bottom: 16px;
      & > div {
        flex: 1;
        &:first-child {
          flex: 2;
        }
      }
    }
  }
  &__desktop {
    display: block;
  }
  &__mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .home {
    &__blocks {
      &__first {
        flex-wrap: wrap;
      }
    }
    &__desktop {
      display: none;
    }
    &__mobile {
      display: block;
      &__extend {
        @include flex(center);
        flex-direction: column;
        gap: 10px;
        & > div {
          width: 100%;
        }
      }
      &__equalize {
        & > div {
          &:first-child {
            flex: 1;
          }
        }
      }
    }
  }
}
.coins {
  @include flex(center, unset);
  gap: 20px;
  &__item {
    &__heading{
      h4 {
        color: #050f19;
        font-size: 20px;
        img {
          width: 25px;
          vertical-align: center;
        }
      }
    }
    &__card {
      padding: 20px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
      border: 0;
      margin-bottom: 30px;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      &.BTC {
        background: linear-gradient(45deg, #f7931a, #ffad41ad);
        ul {
          li {
            p {
              color: #745700;
            }
          }
        }
      }
      &.BCH {
        background: linear-gradient(45deg, #0ac18f, #00db9f99);
        ul {
          li {
            p {
              color: #007425;
            }
          }
        }
      }
      &.LTC {
        background: linear-gradient(45deg, #3063b3, #7babf6);
        ul {
          li {
            p {
              color: #38427f;
            }
          }
        }
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          p {
            padding: 0;
            margin: 0;
          }
          span {
            color: white;
          }
        }
      }
      button {
        background-color: white;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .coins {
    &__item {
      width: 100%;
    }
  }
}